import { Component } from '@angular/core';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
    <span class="created-by">
      Developed by <b><a href="https://odrtechinc.com" target="_blank">ODR Technologies</a></b> 2023
    </span>
   
  `,
})
export class FooterComponent {
}
