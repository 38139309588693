<div class="header-container">
  <div class="logo-container">
    <a
      href="#"
      class="sidebar-toggle"
      matRipple
      [matRippleUnbounded]="true"
      [matRippleCentered]="true"
      (click)="toggleSidebar()"
    >
      <nb-icon [icon]="(materialTheme$ | async) ? 'menu-outline' : 'menu-2-outline'"></nb-icon>
    </a>
      <img src="../../../assets/piko_logo.png" alt="PIKO Logo"/>

    <a class="logo" href="#" (click)="navigateHome()">PIKO Portal</a>
  </div>
  <!-- <nb-select
    status="primary"
    matRipple
    [selected]="currentTheme"
    (selectedChange)="changeTheme($event)"
  >
    <nb-option
      *ngFor="let theme of themes"
      [value]="theme.value"
      matRipple
    >{{ theme.name }}</nb-option>
  </nb-select> -->
</div>

<div class="header-container">
  <nb-actions size="small">
    <nb-action
      class="user-action"
      *nbIsGranted="['view', 'user']"
      matRipple
      [matRippleUnbounded]="true"
      [matRippleCentered]="true">
      <nb-user [nbContextMenu]="userMenu"
               [onlyPicture]="userPictureOnly"
               [name]="user?.username"
               [picture]="user?.picture">
      </nb-user>
    </nb-action>
  </nb-actions>
</div>

<ng-template #dialogRef let-data let-ref="dialogRef">
  <nb-card>
    <nb-card-header>Confirmation</nb-card-header>
    <nb-card-body>Are you sure you want to log out?</nb-card-body>
    <nb-card-footer>
      <button nbButton matRipple (click)="ref.close()">Cancel</button>
      <button nbButton matRipple (click)="logout()">Log out</button>
    </nb-card-footer>
  </nb-card>
</ng-template>