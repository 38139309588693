import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { throwError,BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';



@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private moduleName: string = "User";

  url = environment.endpoint + "/api/" + this.moduleName;
  authState = new BehaviorSubject(false);
  userid = new BehaviorSubject(0);
  
  constructor(
    private http: HttpClient,
    private router: Router,
  ) { }

  getHeaders() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('authToken')
      })
    };
  }
  
  handleError(error: HttpErrorResponse) {
    console.log(error);
    return throwError(error);
  }

  logout(){

    // localStorage.removeItem('authToken')
    this.authState.next(false);
    this.userid.next(0);

    localStorage.clear();
    this.router.navigate(['auth/login']);
    console.log("logout")
  }

  login(data: any) {
    // const url = this.url + '/api/maintenance/Authenticate';
    const url = environment.endpoint + "/api/authenticate";
    // console.log(data);
    // const url = this.url + '/portal/Authenticate';
    return new Promise(resolve => {
      this.http.post(url, data, this.getHeaders()).subscribe(
        response => {
          let res = response as any;
          console.log(res)

          // if(res.role != "appuser") {
            res = response;
            console.log(res)
            localStorage.setItem('authToken', res['token'])
            localStorage.setItem('pikoadminuser', JSON.stringify(res))  
            localStorage.setItem('pikoUserRole', res.role)  
            this.authState.next(true);
            this.userid.next(res.id);
            resolve(res)
          // } else {
          //   console.log("role is app user, do not log in.");
          //   this.logout();
          // }
        },
        err => {
          this.logout();
          resolve(err)
        }
      );

    }).catch(err => {
      this.logout();
      console.log(err)
    })
  }
}
